import './css/main.css'
import { Link, useNavigate } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass, faCircleXmark, faSortDown, faSortUp, faWindowMinimize } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useRef, useState } from 'react';
import Sidebar from '../Nav/sidebar';
// import NewPage from './NewPage'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { checkLogin } from '../store';
import { SyncLoader } from "react-spinners";
import Message from './common/message';

import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

function Advertisement() {
    const [search, setSearch] = useState("")
    const [select, setSelect] = useState(false)
    const [selectValue, setSelectValue] = useState("Email")
    const [page, setPage] = useState(1)
    const [memberData, setMemberData] = useState([])
    const [memberDatalist, setMemberDataList] = useState([])
    const [reload, setReload] = useState(false)
    const [spinner, setSpinner] = useState(false)
    const [kol0List, setKol0List] = useState("")
    const [message, setMessage] = useState({ code: -1, msg: "" })

    let dark = useSelector((state) => { return state.Darkmode.value });
    let SelectType = useSelector((state) => { return state.SelectType.value });
    let ex_name = useSelector((state) => { return state.ex_name.value });

    const darkname = dark ? 'dark' : "";

    let dispatch = useDispatch();
    const navigate = useNavigate();

    // useEffect(() => {
    //     const objString = localStorage.getItem('token');
    //     if (objString != null) {
    //         const obj = JSON.parse(objString);
    //         if (Date.now() > obj.expire) {
    //             localStorage.removeItem('token');
    //             dispatch(checkLogin(false));
    //             navigate('/')
    //         }
    //         else {
    //             dispatch(checkLogin(true));
    //         }
    //     }
    //     else {
    //         dispatch(checkLogin(false));
    //         navigate('/')
    //     }
    // }, [])

    const onChangeSearch = (e) => {
        setSearch(e.target.value)
    }

    useEffect(() => {
        onSpinner()
        setPage(1)
        MemberData()
    }, [reload])

    const MemberData = async () => {
        // const objString = localStorage.getItem('token');
        // if (objString != null) {
        //     const obj = JSON.parse(objString);
        //     let token = obj.value;

        //     await axios.get(process.env.REACT_APP_API + '/api/v1/member', {
        //         headers: {
        //             Authorization: `Bearer ${token}`
        //         }
        //     }).then((response) => {
        //         let tmp = response.data.data
        //         if (tmp) {
        //             setMemberData(tmp)
        //         }
        //     }).catch(error => {
        //         console.error(error);
        //     });
        // }
    }

    const onSpinner = () => {
        setSpinner(true)
        setTimeout(() => {
            setSpinner(false)
        }, 500)
    }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            setReload(e => !e)
        }
    };

    const excelData = () => {
        // let arr = []
        // if (memberDatalist) {
        //     for (let i = 0; i < memberDatalist.length; i++) {
        //         let t = memberDatalist[i]
        //         arr.push({
        //             "RFRevID": t.uid,
        //             "Email": t.email ? t.email : "-",
        //             "Mobile": t.mobile ? t.mobile : "-",
        //             "Level": t.level,
        //             "Master": t.level == "AGENT" ? t.master ? t.master : "0" : "N/A",
        //             "Status": t.status,
        //         })
        //     }
        // }
        // Excel(arr, SelectType, ex_name)
    }

    const [data, setData] = useState([])

    useEffect(() => {
        UpgradeData()
    }, [])

    const UpgradeData = async () => {
        await axios.get(process.env.REACT_APP_API + '/api/ad', {
            // headers: { Authorization: `Bearer ${JWT}` }
        }).then((response) => {
            let tmp = response.data
            if (tmp?.code == 1000) {
                console.log(tmp.data)
                setData(tmp?.data?.ad ?? [])
            }
        }).catch((error) => {
            console.log(error)
        })
    };

    let slice_data = data.slice((page - 1) * 20, (page) * 20)

    const [formData, setFormData] = useState({
        TITLE: "", // 광고 이름
        REDIRECT_URL: "", // 리디렉션 URL
        START_DATE: "", // 시작 날짜
        END_DATE: "", // 종료 날짜
        file: '', // 업로드된 파일
    });
    const [imagePreviews, setImagePreviews] = useState([]);

    const handleChange = (e) => {
        const { name, value, type, checked, files } = e.target;

        if (type === 'checkbox') {
            setFormData({ ...formData, [name]: checked });
        } else if (type === 'file') {
            setFormData({ ...formData, file: files });
            handleImagePreview(files);
        } else {
            setFormData({ ...formData, [name]: value });
        }
    };

    // 이미지 미리보기 처리 함수
    const handleImagePreview = (files) => {
        const previewArray = Array.from(files).map((file) => {
            const reader = new FileReader();
            return new Promise((resolve) => {
                reader.onloadend = () => {
                    resolve(reader.result); // 파일의 base64 데이터를 resolve
                };
                reader.readAsDataURL(file); // 파일을 base64로 변환
            });
        });

        Promise.all(previewArray).then((images) => {
            setImagePreviews(images); // 미리보기 이미지들을 저장
        });
    };

    const onSubmit = async () => {
        const formDataObj = new FormData();

        formDataObj.append('adName', formData.TITLE);
        formDataObj.append('adRedirectUrl', formData.REDIRECT_URL); // 리디렉션 URL 추가
        formDataObj.append('start', formData.START_DATE); // 시작 날짜
        formDataObj.append('end', formData.END_DATE); // 종료 날짜
        if (formData.file.length > 0) {
            formDataObj.append("file", formData.file[0]);
        } else {
            alert("이미지를 선택하세요.");
            return;
        }

        await axios.post(process.env.REACT_APP_API + "/api/ad", formDataObj, {
            headers: { 'Content-Type': 'multipart/form-data' },
        }).then((response) => {
            console.log(response.data)
            let tmp = response?.data
            if (tmp?.code < 2000) {

            }
            else {
                // setMessage({ msg: "등록에 실패했습니다." })
            }
        }).catch(error => {
            console.error(error);
        });
    }

    return (
        <div className='main'>
            <div className='budget-box'>
                <div style={{ marginBottom: "20px", fontSize: "18px", fontWeight: "bold" }}>광고 등록</div>
                <div className='budget-2'>
                    <div className='budget-1-3'>이미지 첨부</div>
                    <div className='budget-1-2'>
                        <label htmlFor="file" className="form-control file_form" style={{ display: "block" }}>
                            {formData.file ? Array.from(formData.file).map(file => file.name).join(', ') : "파일선택"}
                        </label>
                        <input type="file" id="file" name="file" style={{ display: "none" }} multiple accept="image/*" onChange={handleChange} />
                    </div>
                </div>
                <div className='budget-2'>
                    <div className='budget-1-3'>광고 이름</div>
                    <div className='budget-1-2'>
                        <input type="text" name="TITLE" placeholder="광고 이름" value={formData.TITLE || ""} onChange={handleChange} />
                    </div>
                </div>
                <div className='budget-2'>
                    <div className='budget-1-3'>리디렉션 URL</div>
                    <div className='budget-1-2'>
                        <input type="text" name="REDIRECT_URL" placeholder="http://example.com" value={formData.REDIRECT_URL || ""} onChange={handleChange} />
                    </div>
                </div>
                <div className='budget-2'>
                    <div className='budget-1-3'>시작 날짜</div>
                    <div className='budget-1-2'>
                        <input type="date" name="START_DATE" value={formData.START_DATE || ""} onChange={handleChange} />
                    </div>
                </div>
                <div className='budget-2'>
                    <div className='budget-1-3'>종료 날짜</div>
                    <div className='budget-1-2'>
                        <input type="date" name="END_DATE" value={formData.END_DATE || ""} onChange={handleChange} />
                    </div>
                </div>
                <div className='budget-2'>
                    <input type="button" value="등록" onClick={onSubmit} />
                </div>
            </div>
            <div className={`main-box ${darkname}`}>
                {/* <div className='tradingType-1' style={{ marginTop: "10px" }} >
                    <div className={`tradingType-1-2 ${darkname}`} onClick={() => excelData()}>Excel download</div>
                </div> */}
                <div className={`member-2 ${darkname}`}>
                    <div className={`table-head ${darkname}`}>
                        <div>배너 미리보기</div>
                        <div>이름</div>
                        <div>파일명</div>
                        <div>연결 URL</div>
                        <div>시작일</div>
                        <div>종료일</div>
                        <div>활성화</div>
                        <div>계약일(등록일)</div>
                        <div>수정</div>
                    </div>
                    <div className='table-body' style={{ minHeight: "600px" }}>
                        {
                            spinner
                                ?
                                <div className='spinner-modal'>
                                    <SyncLoader color="#003577" />
                                </div>
                                :
                                slice_data.length === 0
                                    ?
                                    <div className='data-nofound'>
                                        <img src='/img/img_nofound.png'></img>
                                        <img src='/img/img_nofound_dark.svg' style={{ display: "none" }}></img>
                                        <p>NO RECORDS FOUND.</p>
                                    </div>
                                    :
                                    <>
                                        {
                                            slice_data.map(function (a, i) {
                                                return (
                                                    <MemberItem key={i} item={a} darkname={darkname} dark={dark} setReload={setReload} kol0List={kol0List} setMessage={setMessage} />
                                                )
                                            })
                                        }
                                        {/* <NewPage initialData={memberDatalist} page={page} setPage={setPage} maxFirstPage={20} /> */}
                                    </>

                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

function MemberItem({ item, darkname, dark, setReload, kol0List, setMessage }) {
    let SelectType = useSelector((state) => { return state.SelectType.value });
    const [clickId, setClickId] = useState(false)
    const [clickEXUID, setClickEXUID] = useState(false)
    const [EXUIDtype, setEXUIDtype] = useState(0)

    return (
        <>
            <div className={`table-body-tr ${darkname}`}>
                <div className='td'><img src={"https://dev.ma.ttory.com/" + item.ad_img} style={{ height: '30px' }}></img></div>
                <div className='td'>{item.ad_name}</div>
                <div className='td'>{item.ad_img.split('/').pop()}</div>
                <div className='td'>{item.ad_redirect_url}</div>
                <div className='td'>{item.ad_start}</div>
                <div className='td'>{item.ad_end}</div>
                <div className='td'>{item.ad_status === "1" ? "활성화" : "비활성화"}</div>
                <div className='td'>{item.reg_date}</div>
                <div className='td'></div>
            </div>
        </>
    )
}

export default Advertisement;