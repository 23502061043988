import './css/main.css'
import { Link, useNavigate } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass, faCircleXmark, faSortDown, faSortUp, faWindowMinimize, faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useRef, useState } from 'react';
import Sidebar from '../Nav/sidebar';
import NewPage from './NewPage'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { checkLogin } from '../store';
import { SyncLoader } from "react-spinners";
import Message from './common/message';
import { ListRestart } from 'lucide-react';

import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

function Member() {
    const [search, setSearch] = useState("")
    const [select, setSelect] = useState(false)
    const [selectValue, setSelectValue] = useState("ID")
    const [page, setPage] = useState(1)
    const [reload, setReload] = useState(false)
    const [spinner, setSpinner] = useState(false)
    const [message, setMessage] = useState({ code: -1, msg: "" })

    const [skinModal, setSkinModal] = useState(false)
    const [dianModal, setDiaModal] = useState(false)
    const [clickId, setClickId] = useState([])

    const [data, setData] = useState([])
    const [dataList, setDataList] = useState([])

    let dark = useSelector((state) => { return state.Darkmode.value });
    let SelectType = useSelector((state) => { return state.SelectType.value });
    let ex_name = useSelector((state) => { return state.ex_name.value });

    const darkname = dark ? 'dark' : "";

    let dispatch = useDispatch();
    const navigate = useNavigate();

    // useEffect(() => {
    //     const objString = localStorage.getItem('token');
    //     if (objString != null) {
    //         const obj = JSON.parse(objString);
    //         if (Date.now() > obj.expire) {
    //             localStorage.removeItem('token');
    //             dispatch(checkLogin(false));
    //             navigate('/')
    //         }
    //         else {
    //             dispatch(checkLogin(true));
    //         }
    //     }
    //     else {
    //         dispatch(checkLogin(false));
    //         navigate('/')
    //     }
    // }, [])

    const onChangeSearch = (e) => {
        setSearch(e.target.value)
    }

    useEffect(() => {
        if (data && data.length > 0) {
            let filter = [];
            if (selectValue === "ID" && search.length > 0) {
                filter = data.filter((p) => {
                    if (p.id) return p.id.toString().includes(search);
                });
            } else {
                filter = data;
            }
            setDataList(filter);
        }
    }, [data])

    const onSpinner = () => {
        setSpinner(true)
        setTimeout(() => {
            setSpinner(false)
        }, 500)
    }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            setReload(e => !e)
        }
    };

    const excelData = () => {
        // let arr = []
        // if (memberDatalist) {
        //     for (let i = 0; i < memberDatalist.length; i++) {
        //         let t = memberDatalist[i]
        //         arr.push({
        //             "RFRevID": t.uid,
        //             "Email": t.email ? t.email : "-",
        //             "Mobile": t.mobile ? t.mobile : "-",
        //             "Level": t.level,
        //             "Master": t.level == "AGENT" ? t.master ? t.master : "0" : "N/A",
        //             "Status": t.status,
        //         })
        //     }
        // }
        // Excel(arr, SelectType, ex_name)
    }

    useEffect(() => {
        onSpinner()
        setPage(1)
        UpgradeData()
    }, [reload])

    const UpgradeData = async () => {
        await axios.get(process.env.REACT_APP_API + '/api/user', {
            // headers: { Authorization: `Bearer ${JWT}` }
        }).then((response) => {
            let tmp = response.data
            if (tmp?.code == 1000) {
                console.log(tmp.data)
                setData(tmp?.data?.user ?? [])
            }
        }).catch((error) => {
            console.log(error)
        })
    };

    let slice_data = dataList.slice((page - 1) * 20, (page) * 20)

    return (
        <div className='main'>
            <Message message={message} />
            <Sidebar page={0} />
            {skinModal && (<MemberModal darkname={darkname} setSkinModal={setSkinModal} clickId={clickId} setReload={setReload} />)}
            {dianModal && (<DiaModal darkname={darkname} setDiaModal={setDiaModal} clickId={clickId} setReload={setReload} />)}
            <div className={`main-box ${darkname}`}>
                <div className='member-1'>
                    <div className={`member-2-search ${darkname}`}>
                        <div className={`member-1-select ${darkname}`} onClick={() => setSelect(e => !e)}>
                            <div className={`member-1-select-1 ${darkname}`}>{selectValue}</div>
                            {
                                select
                                    ?
                                    <FontAwesomeIcon icon={faSortUp} />
                                    :
                                    <FontAwesomeIcon icon={faSortDown} />
                            }
                            {
                                select
                                    ? <>
                                        <div className='modal' onClick={() => setSelect(true)}></div>
                                        <div className={`member-1-select-box ${darkname}`}>
                                            <div onClick={() => { setSelectValue(`ID`); setSelect(true) }}>ID</div>
                                            <div onClick={() => { setSelectValue(`Name`); setSelect(true) }}>Name</div>
                                        </div>
                                    </>
                                    :
                                    ""
                            }
                        </div>
                        <FontAwesomeIcon icon={faWindowMinimize} className='line' />
                        <div className={`member-2-search-1 ${darkname}`}>
                            <input value={search} onChange={onChangeSearch} placeholder='Search' onKeyDown={handleKeyPress} />
                            <FontAwesomeIcon icon={faMagnifyingGlass} className='member-1-search-ico-3' />
                            {
                                search.length > 0
                                    ?
                                    <FontAwesomeIcon icon={faCircleXmark} className='member-1-search-ico-2' onClick={() => setSearch("")} />
                                    :
                                    ""
                            }
                        </div>
                    </div>
                    <div className={`search-btn ${darkname}`} onClick={() => setReload(e => !e)}>Search</div>
                </div>
                <div className='tradingType-1' style={{ marginTop: "10px" }} >
                    <div className={`tradingType-1-2 ${darkname}`} onClick={() => excelData()}>Excel download</div>
                </div>
                <div className={`member-2 ${darkname}`}>
                    <div className={`table-head ${darkname}`}>
                        <div style={{ width: "10%", minWidth: "150px" }}>ID</div>
                        <div style={{ width: "30%", minWidth: "150px" }}>이름</div>
                        <div style={{ width: "15%", minWidth: "70px" }}>스킨</div>
                        <div style={{ width: "20%", minWidth: "130px" }}>다이아</div>
                        <div style={{ width: "20%", minWidth: "100px" }}>에너지</div>
                        <div style={{ width: "35%", minWidth: "250px" }}>강화 상태</div>
                        <div style={{ width: "35%", minWidth: "250px" }}>최근 접속</div>
                        <div style={{ width: "25%", minWidth: "200px" }}>가입일</div>
                        <div style={{ width: "40%", minWidth: "300px" }}>기타 설명</div>
                        <div style={{ width: "10%", minWidth: "100px" }}>리셋</div>
                    </div>
                    <div className='table-body' style={{ minHeight: "600px" }}>
                        {
                            spinner
                                ?
                                <div className='spinner-modal'>
                                    <SyncLoader color="#003577" />
                                </div>
                                :
                                dataList.length === 0
                                    ?
                                    <div className='data-nofound'>
                                        <img src='/img/img_nofound.png'></img>
                                        <img src='/img/img_nofound_dark.svg' style={{ display: "none" }}></img>
                                        <p>NO RECORDS FOUND.</p>
                                    </div>
                                    :
                                    <>
                                        {
                                            slice_data.map(function (a, i) {
                                                return (
                                                    <MemberItem key={i} item={a} darkname={darkname} dark={dark} setClickId={setClickId} setSkinModal={setSkinModal} setDiaModal={setDiaModal} setReload={setReload} />
                                                )
                                            })
                                        }
                                        <NewPage initialData={dataList} page={page} setPage={setPage} maxFirstPage={20} />
                                    </>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

function MemberItem({ item, darkname, setClickId, setSkinModal, setDiaModal, setReload }) {
    const onSubmitReset = async () => {
        if (window.confirm("리셋하시겠습니까?")) {
            await axios.post(process.env.REACT_APP_API + '/api/user/reset', {
                userId: item.id,
            }, {
            }).then((response) => {
                let tmp = response.data
                if (tmp?.code == 1000) {
                    setReload(e => !e)
                }
            }).catch((error) => {
                console.log(error)
            })
        }
    };

    return (
        <>
            <div className={`table-body-tr member ${darkname}`}>
                <div className='right td' style={{ width: "10%", minWidth: "150px" }}>{item.id}</div>
                <div className='left td' style={{ width: "30%", minWidth: "150px" }}>{item.user_name}</div>
                <div className='onclick td' style={{ width: "15%", minWidth: "70px" }} onClick={() => { setClickId(item); setSkinModal(true) }}>{item.user_current_skin}</div>
                <div className='onclick right td' style={{ width: "20%", minWidth: "130px" }} onClick={() => { setClickId(item); setDiaModal(true) }}>{item.user_gold}<FontAwesomeIcon icon={faPenToSquare} style={{ marginLeft: "5px", color: 'gray' }} /></div>
                <div className='right td' style={{ width: "20%", minWidth: "100px" }}>{item.user_last_energy}</div>
                <div className='td' style={{ width: "35%", minWidth: "250px" }}>{item.user_upgrade_status}</div>
                <div className='td' style={{ width: "35%", minWidth: "250px" }}>{item.access_date + "/" + item.access_ip}</div>
                <div className='td' style={{ width: "25%", minWidth: "200px" }}>{item.reg_date}</div>
                <div className='left td' style={{ width: "40%", minWidth: "300px" }}>{item.desc}</div>
                <div className='onclick td' style={{ width: "10%", minWidth: "100px" }} onClick={() => onSubmitReset()}>
                    <ListRestart style={{ color: "gray" }} />
                </div>
            </div>
        </>
    )
}

function MemberModal({ darkname, setSkinModal, clickId, setReload }) {
    const [spinner, setSpinner] = useState(false)
    const [page, setPage] = useState(1)
    const [skinList, setSkinList] = useState([])

    const onSpinner = () => {
        setSpinner(true)
        setTimeout(() => {
            setSpinner(false)
        }, 500)
    }

    useEffect(() => {
        SkinData()
    }, [])

    const SkinData = async () => {
        await axios.get(process.env.REACT_APP_API + '/api/user/owned', {
            params: {
                userId: clickId.id
            },
            // headers: { Authorization: `Bearer ${JWT}` }
        }).then((response) => {
            let tmp = response.data
            if (tmp?.code == 1000) {
                console.log(tmp.data)
                setSkinList(tmp?.data?.owned ?? [])
            }
        }).catch((error) => {
            console.log(error)
        })
    };

    return (
        <div className='modal'>
            <div className='modal-box'>
                <div className='modal-1' style={{ width: "800px" }}>
                    <div className='modal-1-header'>보유스킨 확인</div>
                    <div className={`member-2 ${darkname}`}>
                        <div className={`table-head ${darkname}`}>
                            {/* <div>ID</div> */}
                            <div>미리보기</div>
                            <div>이름</div>
                        </div>
                        <div className='table-body' style={{ minHeight: "500px", height: "500px", overflow: "scroll" }}>
                            {
                                spinner
                                    ?
                                    <div className='spinner-modal'>
                                        <SyncLoader color="#003577" />
                                    </div>
                                    :
                                    skinList.length === 0
                                        ?
                                        <div className='data-nofound'>
                                            <img src='/img/img_nofound.png'></img>
                                            <img src='/img/img_nofound_dark.svg' style={{ display: "none" }}></img>
                                            <p>NO RECORDS FOUND.</p>
                                        </div>
                                        :
                                        <>
                                            {
                                                skinList.map(function (a, i) {
                                                    return (
                                                        <SkinItem key={i} item={a} darkname={darkname} setReload={setReload} />
                                                    )
                                                })
                                            }
                                        </>

                            }
                        </div>
                    </div>

                    <div className='modal-btn-box'>
                        <div className={`modal-btn ${darkname}`} onClick={() => setSkinModal(false)}>Close</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function SkinItem({ item, darkname }) {
    return (
        <div className={`table-body-tr ${darkname}`} style={{ height: "70px" }}>
            <div className='td'><img src={item.skin_img} style={{ height: '100%' }}></img></div>
            <div className='td'>{item.skin_name}</div>
        </div>
    )
}

function DiaModal({ darkname, setDiaModal, clickId, setReload }) {
    const [input, setInput] = useState("")
    const onChangeInput = (e) => {
        const { value } = e.target
        const onlyNumber = value.replace(/[^0-9]/g, "")
        setInput(onlyNumber)
    }

    const onSubmitUpdateGold = async () => {
        await axios.post(process.env.REACT_APP_API + '/api/user/give-gold', {
            userId: clickId.id,
            gold: input
        }, {
        }).then((response) => {
            let tmp = response.data
            if (tmp?.code == 1000) {
                setDiaModal(false)
                setReload(e => !e)
            }
        }).catch((error) => {
            console.log(error)
        })
    };

    return (
        <div className='modal'>
            <div className='modal-box'>
                <div className='modal-1' style={{ width: "400px" }}>
                    <div className='modal-1-header'>보유 다이아 수정</div>

                    <div style={{ marginBottom: "30px" }}>
                        <div style={{ width: "100%", textAlign: "left" }}>
                            <div style={{ fontSize: "14px", paddingBottom: "3px" }}>보유 다이아</div>
                            <div className={`password-1 ${darkname}`}>
                                <div>{clickId.user_gold}</div>
                            </div>
                        </div>
                        <div style={{ width: "100%", textAlign: "left" }}>
                            <div style={{ fontSize: "14px", paddingBottom: "3px" }}>추가할 다이아 수량</div>
                            <div className={`password-1 ${darkname}`}>
                                <input value={input} onChange={onChangeInput} placeholder='추가할 다이아 수량' />
                            </div>
                        </div>
                    </div>
                    <div className='modal-btn-box'>
                        <div className={`modal-btn ${darkname}`} onClick={() => { onSubmitUpdateGold() }}>Confirm</div>
                        <div className={`modal-btn ${darkname}`} onClick={() => setDiaModal(false)}>Close</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Member;