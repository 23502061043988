import './nav.css'
import { Link, useLocation, useNavigate } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import base64 from "base-64";
import { change_sidebar_dropdown } from '../store';
import { Camera } from 'lucide-react';

function Sidebar({ page }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    let location = useLocation();

    let dark = useSelector((state) => { return state.Darkmode.value });
    let login = useSelector((state) => { return state.login.value; });
    let dropdown = useSelector((state) => { return state.sidebar_dropdown });
    let SelectType = useSelector((state) => { return state.SelectType.value });

    const [id, setId] = useState("")

    const onChangeDropDown1 = (e) => {
        dispatch(change_sidebar_dropdown({ num: 0, value: !dropdown[0] }))
    }
    const onChangeDropDown2 = (e) => {
        dispatch(change_sidebar_dropdown({ num: 1, value: !dropdown[1] }))
    }

    const darkname = dark ? 'dark' : "";

    useEffect(() => {
        let objString = localStorage.getItem("sidebar_dropdown");
        if (objString != null) {
            let arr = objString.split(",");
            for (let i = 0; i < arr.length; i++) {
                dispatch(change_sidebar_dropdown({ num: i, value: arr[i] == "false" ? false : true }));
            }
        }
    }, []);

    useEffect(() => {
        let objString = localStorage.getItem('token')
        if (objString != null) {
            const obj = JSON.parse(objString);
            let token = obj.value;
            let payload = token.substring(
                token.indexOf(".") + 1,
                token.lastIndexOf(".")
            );
            let dec = base64.decode(payload);
            let sub = JSON.parse(dec).authority
            setId(sub)
        }
    }, [login])

    const [loca, setLoca] = useState(0)

    useEffect(() => {
        if (location) {
            if (location.pathname == '/dashboard') setLoca(0)
            else if (location.pathname == '/member') setLoca(1)
            else if (location.pathname == '/budget') setLoca(2)
            else if (location.pathname == '/advertisement') setLoca(3)
            else if (location.pathname == '/character') setLoca(4)
            else if (location.pathname == '/gameSetting') setLoca(5)
            else if (location.pathname == '/adminSetting') setLoca(6)
            else if (location.pathname == '/logJackpot') setLoca(7)
            else if (location.pathname == '/upgradeSetting') setLoca(8)
            else if (location.pathname == '/logAccess') setLoca(9)
            else if (location.pathname == '/logGold') setLoca(10)
            else if (location.pathname == '/logEnergy') setLoca(11)
            else setLoca(7)
        }
    }, [location])

    return (
        <div className={`sidebar ${darkname}`}>
            <div className='sidebar-box'>
                {/* <Link to={`/dashboard`}>
                    <div className={loca === 0 ? `sidebar-box-1 on  ${darkname}` : `sidebar-box-1 ${darkname}`}>
                        <svg viewBox="0 0 19 19">
                            <g id="그룹_530" data-name="그룹 530" transform="translate(1508 9772)">
                                <g id="사각형_490" data-name="사각형 490" className="cls-2" transform="translate(-1508 -9772)">
                                    <path className="cls-3" d="M0,0H9A0,0,0,0,1,9,0V7A2,2,0,0,1,7,9H0A0,0,0,0,1,0,9V0A0,0,0,0,1,0,0Z" />
                                    <path className="cls-4" d="M.5.5h8a0,0,0,0,1,0,0V7A1.5,1.5,0,0,1,7,8.5H.5a0,0,0,0,1,0,0V.5A0,0,0,0,1,.5.5Z" />
                                </g>
                                <g id="사각형_491" data-name="사각형 491" className="cls-1" transform="translate(-1498 -9772)">
                                    <path className="cls-3" d="M0,0H9A0,0,0,0,1,9,0V9A0,0,0,0,1,9,9H2A2,2,0,0,1,0,7V0A0,0,0,0,1,0,0Z" />
                                    <path className="cls-3" d="M.5.5h8a0,0,0,0,1,0,0v8a0,0,0,0,1,0,0H2A1.5,1.5,0,0,1,.5,7V.5A0,0,0,0,1,.5.5Z" />
                                </g>
                                <g id="사각형_492" data-name="사각형 492" className="cls-2" transform="translate(-1498 -9762)">
                                    <path className="cls-3" d="M2,0H9A0,0,0,0,1,9,0V9A0,0,0,0,1,9,9H0A0,0,0,0,1,0,9V2A2,2,0,0,1,2,0Z" />
                                    <path className="cls-4" d="M2,.5H8.5a0,0,0,0,1,0,0v8a0,0,0,0,1,0,0H.5a0,0,0,0,1,0,0V2A1.5,1.5,0,0,1,2,.5Z" />
                                </g>
                                <g id="사각형_493" data-name="사각형 493" className="cls-1" transform="translate(-1508 -9762)">
                                    <path className="cls-3" d="M0,0H7A2,2,0,0,1,9,2V9A0,0,0,0,1,9,9H0A0,0,0,0,1,0,9V0A0,0,0,0,1,0,0Z" />
                                    <path className="cls-3" d="M.5.5H7A1.5,1.5,0,0,1,8.5,2V8.5a0,0,0,0,1,0,0H.5a0,0,0,0,1,0,0V.5A0,0,0,0,1,.5.5Z" />
                                </g>
                            </g>
                        </svg>
                        <div className='sidebar-box-1-1'>대시보드</div>
                    </div>
                </Link> */}
                <Link to={`/member`}>
                    <div className={loca === 1 ? `sidebar-box-1 on  ${darkname}` : `sidebar-box-1 ${darkname}`}>
                        <svg width="21.187" height="18" viewBox="0 0 21.187 18">
                            <g className="cls-2">
                                <path id="패스_1" data-name="패스 1" className="cls-1" d="M14.141,5.553A4.609,4.609,0,1,1,9.532.944a4.608,4.608,0,0,1,4.609,4.609" transform="translate(0.904 0.173)" />
                                <path id="패스_2" data-name="패스 2" className="cls-1" d="M20.764,11.946c0,2.536-4.649,4.605-10.382,4.605S0,14.482,0,11.946C0,10.19,2.2,8.673,5.44,7.893a5.388,5.388,0,0,0,4.941,2.871,5.4,5.4,0,0,0,4.943-2.871c3.239.78,5.44,2.3,5.44,4.053" transform="translate(0 1.449)" />
                                <path id="패스_3" data-name="패스 3" className="cls-1" d="M18.126,1.929H16.738V.542a.542.542,0,1,0-1.084,0V1.929H14.267a.542.542,0,0,0,0,1.084h1.387V4.4a.542.542,0,1,0,1.084,0V3.013h1.387a.542.542,0,0,0,0-1.084" transform="translate(2.52 0)" />
                            </g>
                        </svg>
                        <div className='sidebar-box-1-1'>유저 관리</div>
                    </div>
                </Link>
                <Link to={`/budget`}>
                    <div className={loca === 2 ? `sidebar-box-1 on  ${darkname}` : `sidebar-box-1 ${darkname}`}>
                        <svg width="21.187" height="18" viewBox="0 0 21.187 18">
                            <g className="cls-2">
                                <path id="패스_1" data-name="패스 1" className="cls-1" d="M14.141,5.553A4.609,4.609,0,1,1,9.532.944a4.608,4.608,0,0,1,4.609,4.609" transform="translate(0.904 0.173)" />
                                <path id="패스_2" data-name="패스 2" className="cls-1" d="M20.764,11.946c0,2.536-4.649,4.605-10.382,4.605S0,14.482,0,11.946C0,10.19,2.2,8.673,5.44,7.893a5.388,5.388,0,0,0,4.941,2.871,5.4,5.4,0,0,0,4.943-2.871c3.239.78,5.44,2.3,5.44,4.053" transform="translate(0 1.449)" />
                                <path id="패스_3" data-name="패스 3" className="cls-1" d="M18.126,1.929H16.738V.542a.542.542,0,1,0-1.084,0V1.929H14.267a.542.542,0,0,0,0,1.084h1.387V4.4a.542.542,0,1,0,1.084,0V3.013h1.387a.542.542,0,0,0,0-1.084" transform="translate(2.52 0)" />
                            </g>
                        </svg>
                        <div className='sidebar-box-1-1'>버짓 관리</div>
                    </div>
                </Link>
                <Link to={`/gameSetting`}>
                    <div className={loca === 5 ? `sidebar-box-1 on  ${darkname}` : `sidebar-box-1 ${darkname}`}>
                        <svg width="21.187" height="18" viewBox="0 0 21.187 18">
                            <g className="cls-2">
                                <path id="패스_1" data-name="패스 1" className="cls-1" d="M14.141,5.553A4.609,4.609,0,1,1,9.532.944a4.608,4.608,0,0,1,4.609,4.609" transform="translate(0.904 0.173)" />
                                <path id="패스_2" data-name="패스 2" className="cls-1" d="M20.764,11.946c0,2.536-4.649,4.605-10.382,4.605S0,14.482,0,11.946C0,10.19,2.2,8.673,5.44,7.893a5.388,5.388,0,0,0,4.941,2.871,5.4,5.4,0,0,0,4.943-2.871c3.239.78,5.44,2.3,5.44,4.053" transform="translate(0 1.449)" />
                                <path id="패스_3" data-name="패스 3" className="cls-1" d="M18.126,1.929H16.738V.542a.542.542,0,1,0-1.084,0V1.929H14.267a.542.542,0,0,0,0,1.084h1.387V4.4a.542.542,0,1,0,1.084,0V3.013h1.387a.542.542,0,0,0,0-1.084" transform="translate(2.52 0)" />
                            </g>
                        </svg>
                        <div className='sidebar-box-1-1'>게임 설정</div>
                    </div>
                </Link>
                <Link to={`/upgradeSetting`}>
                    <div className={loca === 8 ? `sidebar-box-1 on  ${darkname}` : `sidebar-box-1 ${darkname}`}>
                        <svg width="21.187" height="18" viewBox="0 0 21.187 18">
                            <g className="cls-2">
                                <path id="패스_1" data-name="패스 1" className="cls-1" d="M14.141,5.553A4.609,4.609,0,1,1,9.532.944a4.608,4.608,0,0,1,4.609,4.609" transform="translate(0.904 0.173)" />
                                <path id="패스_2" data-name="패스 2" className="cls-1" d="M20.764,11.946c0,2.536-4.649,4.605-10.382,4.605S0,14.482,0,11.946C0,10.19,2.2,8.673,5.44,7.893a5.388,5.388,0,0,0,4.941,2.871,5.4,5.4,0,0,0,4.943-2.871c3.239.78,5.44,2.3,5.44,4.053" transform="translate(0 1.449)" />
                                <path id="패스_3" data-name="패스 3" className="cls-1" d="M18.126,1.929H16.738V.542a.542.542,0,1,0-1.084,0V1.929H14.267a.542.542,0,0,0,0,1.084h1.387V4.4a.542.542,0,1,0,1.084,0V3.013h1.387a.542.542,0,0,0,0-1.084" transform="translate(2.52 0)" />
                            </g>
                        </svg>
                        <div className='sidebar-box-1-1'>강화 설정</div>
                    </div>
                </Link>
                <Link to={`/character`}>
                    <div className={loca === 4 ? `sidebar-box-1 on  ${darkname}` : `sidebar-box-1 ${darkname}`}>
                        <svg width="21.187" height="18" viewBox="0 0 21.187 18">
                            <g className="cls-2">
                                <path id="패스_1" data-name="패스 1" className="cls-1" d="M14.141,5.553A4.609,4.609,0,1,1,9.532.944a4.608,4.608,0,0,1,4.609,4.609" transform="translate(0.904 0.173)" />
                                <path id="패스_2" data-name="패스 2" className="cls-1" d="M20.764,11.946c0,2.536-4.649,4.605-10.382,4.605S0,14.482,0,11.946C0,10.19,2.2,8.673,5.44,7.893a5.388,5.388,0,0,0,4.941,2.871,5.4,5.4,0,0,0,4.943-2.871c3.239.78,5.44,2.3,5.44,4.053" transform="translate(0 1.449)" />
                                <path id="패스_3" data-name="패스 3" className="cls-1" d="M18.126,1.929H16.738V.542a.542.542,0,1,0-1.084,0V1.929H14.267a.542.542,0,0,0,0,1.084h1.387V4.4a.542.542,0,1,0,1.084,0V3.013h1.387a.542.542,0,0,0,0-1.084" transform="translate(2.52 0)" />
                            </g>
                        </svg>
                        <div className='sidebar-box-1-1'>케릭터 관리</div>
                    </div>
                </Link>
                <Link to={`/advertisement`}>
                    <div className={loca === 3 ? `sidebar-box-1 on  ${darkname}` : `sidebar-box-1 ${darkname}`}>
                        <svg width="21.187" height="18" viewBox="0 0 21.187 18">
                            <g className="cls-2">
                                <path id="패스_1" data-name="패스 1" className="cls-1" d="M14.141,5.553A4.609,4.609,0,1,1,9.532.944a4.608,4.608,0,0,1,4.609,4.609" transform="translate(0.904 0.173)" />
                                <path id="패스_2" data-name="패스 2" className="cls-1" d="M20.764,11.946c0,2.536-4.649,4.605-10.382,4.605S0,14.482,0,11.946C0,10.19,2.2,8.673,5.44,7.893a5.388,5.388,0,0,0,4.941,2.871,5.4,5.4,0,0,0,4.943-2.871c3.239.78,5.44,2.3,5.44,4.053" transform="translate(0 1.449)" />
                                <path id="패스_3" data-name="패스 3" className="cls-1" d="M18.126,1.929H16.738V.542a.542.542,0,1,0-1.084,0V1.929H14.267a.542.542,0,0,0,0,1.084h1.387V4.4a.542.542,0,1,0,1.084,0V3.013h1.387a.542.542,0,0,0,0-1.084" transform="translate(2.52 0)" />
                            </g>
                        </svg>
                        <div className='sidebar-box-1-1'>광고 관리</div>
                    </div>
                </Link>
                {/* <Link to={`/adminSetting`}>
                    <div className={loca === 6 ? `sidebar-box-1 on  ${darkname}` : `sidebar-box-1 ${darkname}`}>
                        <svg width="21.187" height="18" viewBox="0 0 21.187 18">
                            <g className="cls-2">
                                <path id="패스_1" data-name="패스 1" className="cls-1" d="M14.141,5.553A4.609,4.609,0,1,1,9.532.944a4.608,4.608,0,0,1,4.609,4.609" transform="translate(0.904 0.173)" />
                                <path id="패스_2" data-name="패스 2" className="cls-1" d="M20.764,11.946c0,2.536-4.649,4.605-10.382,4.605S0,14.482,0,11.946C0,10.19,2.2,8.673,5.44,7.893a5.388,5.388,0,0,0,4.941,2.871,5.4,5.4,0,0,0,4.943-2.871c3.239.78,5.44,2.3,5.44,4.053" transform="translate(0 1.449)" />
                                <path id="패스_3" data-name="패스 3" className="cls-1" d="M18.126,1.929H16.738V.542a.542.542,0,1,0-1.084,0V1.929H14.267a.542.542,0,0,0,0,1.084h1.387V4.4a.542.542,0,1,0,1.084,0V3.013h1.387a.542.542,0,0,0,0-1.084" transform="translate(2.52 0)" />
                            </g>
                        </svg>
                        <div className='sidebar-box-1-1'>관리자 설정</div>
                    </div>
                </Link> */}
                <Link to={`/logAccess`}>
                    <div className={loca === 9 ? `sidebar-box-1 on  ${darkname}` : `sidebar-box-1 ${darkname}`}>
                        <svg width="21.187" height="18" viewBox="0 0 21.187 18">
                            <g className="cls-2">
                                <path id="패스_1" data-name="패스 1" className="cls-1" d="M14.141,5.553A4.609,4.609,0,1,1,9.532.944a4.608,4.608,0,0,1,4.609,4.609" transform="translate(0.904 0.173)" />
                                <path id="패스_2" data-name="패스 2" className="cls-1" d="M20.764,11.946c0,2.536-4.649,4.605-10.382,4.605S0,14.482,0,11.946C0,10.19,2.2,8.673,5.44,7.893a5.388,5.388,0,0,0,4.941,2.871,5.4,5.4,0,0,0,4.943-2.871c3.239.78,5.44,2.3,5.44,4.053" transform="translate(0 1.449)" />
                                <path id="패스_3" data-name="패스 3" className="cls-1" d="M18.126,1.929H16.738V.542a.542.542,0,1,0-1.084,0V1.929H14.267a.542.542,0,0,0,0,1.084h1.387V4.4a.542.542,0,1,0,1.084,0V3.013h1.387a.542.542,0,0,0,0-1.084" transform="translate(2.52 0)" />
                            </g>
                        </svg>
                        <div className='sidebar-box-1-1'>접속 로그</div>
                    </div>
                </Link>
                <Link to={`/logGold`}>
                    <div className={loca === 10 ? `sidebar-box-1 on  ${darkname}` : `sidebar-box-1 ${darkname}`}>
                        <svg width="21.187" height="18" viewBox="0 0 21.187 18">
                            <g className="cls-2">
                                <path id="패스_1" data-name="패스 1" className="cls-1" d="M14.141,5.553A4.609,4.609,0,1,1,9.532.944a4.608,4.608,0,0,1,4.609,4.609" transform="translate(0.904 0.173)" />
                                <path id="패스_2" data-name="패스 2" className="cls-1" d="M20.764,11.946c0,2.536-4.649,4.605-10.382,4.605S0,14.482,0,11.946C0,10.19,2.2,8.673,5.44,7.893a5.388,5.388,0,0,0,4.941,2.871,5.4,5.4,0,0,0,4.943-2.871c3.239.78,5.44,2.3,5.44,4.053" transform="translate(0 1.449)" />
                                <path id="패스_3" data-name="패스 3" className="cls-1" d="M18.126,1.929H16.738V.542a.542.542,0,1,0-1.084,0V1.929H14.267a.542.542,0,0,0,0,1.084h1.387V4.4a.542.542,0,1,0,1.084,0V3.013h1.387a.542.542,0,0,0,0-1.084" transform="translate(2.52 0)" />
                            </g>
                        </svg>
                        <div className='sidebar-box-1-1'>골드 로그</div>
                    </div>
                </Link>
                <Link to={`/logEnergy`}>
                    <div className={loca === 11 ? `sidebar-box-1 on  ${darkname}` : `sidebar-box-1 ${darkname}`}>
                        <svg width="21.187" height="18" viewBox="0 0 21.187 18">
                            <g className="cls-2">
                                <path id="패스_1" data-name="패스 1" className="cls-1" d="M14.141,5.553A4.609,4.609,0,1,1,9.532.944a4.608,4.608,0,0,1,4.609,4.609" transform="translate(0.904 0.173)" />
                                <path id="패스_2" data-name="패스 2" className="cls-1" d="M20.764,11.946c0,2.536-4.649,4.605-10.382,4.605S0,14.482,0,11.946C0,10.19,2.2,8.673,5.44,7.893a5.388,5.388,0,0,0,4.941,2.871,5.4,5.4,0,0,0,4.943-2.871c3.239.78,5.44,2.3,5.44,4.053" transform="translate(0 1.449)" />
                                <path id="패스_3" data-name="패스 3" className="cls-1" d="M18.126,1.929H16.738V.542a.542.542,0,1,0-1.084,0V1.929H14.267a.542.542,0,0,0,0,1.084h1.387V4.4a.542.542,0,1,0,1.084,0V3.013h1.387a.542.542,0,0,0,0-1.084" transform="translate(2.52 0)" />
                            </g>
                        </svg>
                        <div className='sidebar-box-1-1'>에너지 로그</div>
                    </div>
                </Link>
                <Link to={`/logJackpot`}>
                    <div className={loca === 7 ? `sidebar-box-1 on  ${darkname}` : `sidebar-box-1 ${darkname}`}>
                        <svg width="21.187" height="18" viewBox="0 0 21.187 18">
                            <g className="cls-2">
                                <path id="패스_1" data-name="패스 1" className="cls-1" d="M14.141,5.553A4.609,4.609,0,1,1,9.532.944a4.608,4.608,0,0,1,4.609,4.609" transform="translate(0.904 0.173)" />
                                <path id="패스_2" data-name="패스 2" className="cls-1" d="M20.764,11.946c0,2.536-4.649,4.605-10.382,4.605S0,14.482,0,11.946C0,10.19,2.2,8.673,5.44,7.893a5.388,5.388,0,0,0,4.941,2.871,5.4,5.4,0,0,0,4.943-2.871c3.239.78,5.44,2.3,5.44,4.053" transform="translate(0 1.449)" />
                                <path id="패스_3" data-name="패스 3" className="cls-1" d="M18.126,1.929H16.738V.542a.542.542,0,1,0-1.084,0V1.929H14.267a.542.542,0,0,0,0,1.084h1.387V4.4a.542.542,0,1,0,1.084,0V3.013h1.387a.542.542,0,0,0,0-1.084" transform="translate(2.52 0)" />
                            </g>
                        </svg>
                        <div className='sidebar-box-1-1'>잭팟 로그</div>
                    </div>
                </Link>
            </div>
        </div>
    )
}

export default Sidebar;