import { useEffect, useState } from 'react';
import './css/main.css'
import Chart from 'react-apexcharts';

function Dashboard() {
    let Month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    
    const [bn_volume, setBn_volume] = useState([0, 0])
    const [okx_volume, setOKX_volume] = useState([0, 0])
    const [by_volume, setBy_volume] = useState([0, 0])

    const [bn_year, setBn_year] = useState([])
    const [okx_year, setOKX_year] = useState([])
    const [bybit_year, setBybit_year] = useState([])

    const [series, setSeries] = useState([1, 2])
    const [dataArr1, setDataArr1] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
    const [dataArr2, setDataArr2] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
    const [dataArr3, setDataArr3] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
    const [dataArr4, setDataArr4] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
    const [total, setTotal] = useState([0, 0, 0, 0])
    const [max, setMax] = useState(3000)

    const options = {
        chart: {
            width: 380,
            type: 'donut',
        },
        labels: ['사용된 버짓', '사용되지 않은 버짓'],
        colors: ['#fcd535', '#777777'],
        responsive: [
            {
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200,
                    },
                    legend: {
                        position: 'bottom',
                    },
                },
            },
        ],
    };
    const options1 = {
        chart: {
            height: 350,
            type: 'line',
            stacked: false
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            width: [1, 1, 1, 1]
        },
        colors: ['#ff4560', '#008FFB', '#00E396', '#FEB019'],
        xaxis: {
            categories: Month,
        },
        yaxis: [
            {
                axisTicks: {
                    show: true,
                },
                axisBorder: {
                    show: true,
                    color: '#636363'
                },
                labels: {
                    style: {
                        colors: '#636363',
                    },
                    formatter: function (value) {
                        return Math.floor(value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    }
                },
                title: {
                    text: "Volume",
                    style: {
                        color: '#636363',
                    }
                },
                tooltip: {
                    enabled: true
                },
                tickAmount: 4
            },
            {
                opposite: true,
                axisTicks: {
                    show: true,
                },
                axisBorder: {
                    show: true,
                    color: '#636363'
                },
                labels: {
                    style: {
                        colors: '#636363',
                    },
                    formatter: function (value) {
                        return Math.floor(value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    }
                },
                title: {
                    text: "Commission, Reward, Revenue",
                    style: {
                        color: '#636363',
                    }
                },
                tooltip: {
                    enabled: true
                },
                max: max,
                tickAmount: 4
            },
            {
                seriesName: 'Reward',
                opposite: true,
                axisTicks: {
                    show: true,
                },
                axisBorder: {
                    show: true,
                    color: '#00E396'
                },
                labels: {
                    style: {
                        colors: '#00E396',
                    }
                },
                title: {
                    text: "Reward",
                    style: {
                        color: '#00E396',
                    }
                },
                show: false,
                max: max
            },
            {
                seriesName: 'Revenue',
                opposite: true,
                axisTicks: {
                    show: true,
                },
                axisBorder: {
                    show: true,
                    color: '#FEB019'
                },
                labels: {
                    style: {
                        colors: '#FEB019',
                    },
                },
                title: {
                    text: "Revenue",
                    style: {
                        color: '#FEB019',
                    }
                },
                show: false,
                max: max
            },
        ],
        tooltip: {
            fixed: {
                enabled: true,
                position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
                offsetY: 30,
                offsetX: 60
            },
        },
        legend: {
            horizontalAlign: 'left',
            offsetX: 40
        }
    }
    const options2 = {
        chart: {
            width: 380,
            type: 'donut',
        },
        labels: ['None'],
        colors: ['#777777'],
        responsive: [
            {
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200,
                    },
                    legend: {
                        position: 'bottom',
                    },
                },
            },
        ],
    };

    
    const series1 = [{
        name: 'Volume',
        type: 'line',
        data: dataArr1
    }, {
        name: 'Commission',
        type: 'column',
        data: dataArr2
    }, {
        name: 'Reward',
        type: 'column',
        data: dataArr3
    }, {
        name: 'Revenue',
        type: 'column',
        data: dataArr4
    }];

    const getMonthName = (index) => {
        const months = [
            "January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];
        return months[index];
    }

    const arr2 = Array.from({ length: 12 }, (_, index) => {
        const monthName = getMonthName(index); // 월 이름 가져오기 (사용자 정의 함수)

        return {
            month: monthName,
            amount: dataArr1[index],
            commission: dataArr2[index],
            reward: dataArr3[index]
        };
    });

    return (
        <div className='main'>
            <div style={{ minWidth: "380px", minHeight: "250px" }}>
                {
                    series && series[0] === 0 && series[1] === 0
                        ?
                        <Chart options={options2} series={[1]} type='donut' width={360} />
                        :
                        <Chart options={options} series={series} type='donut' width={380} />
                }
            </div>
            <Chart options={options1} series={series1} type='line' minWidth={715} height={380} />
        </div>
    )
}

export default Dashboard;