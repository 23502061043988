import './css/main.css'
import { Link, useNavigate } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass, faCircleXmark, faSortDown, faSortUp, faWindowMinimize } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useRef, useState } from 'react';
import Sidebar from '../Nav/sidebar';
import NewPage from './NewPage'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { checkLogin } from '../store';
import { SyncLoader } from "react-spinners";
import Message from './common/message';

import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

function Budget() {
    const [search, setSearch] = useState("")
    const [select, setSelect] = useState(false)
    const [selectValue, setSelectValue] = useState("Email")
    const [page, setPage] = useState(1)
    const [memberData, setMemberData] = useState([])
    const [memberDatalist, setMemberDataList] = useState([])
    const [reload, setReload] = useState(false)
    const [spinner, setSpinner] = useState(false)

    const [dianModal, setDiaModal] = useState(false)
    const [clickId, setClickId] = useState([])


    let dark = useSelector((state) => { return state.Darkmode.value });
    let SelectType = useSelector((state) => { return state.SelectType.value });
    let ex_name = useSelector((state) => { return state.ex_name.value });

    const darkname = dark ? 'dark' : "";

    let dispatch = useDispatch();
    const navigate = useNavigate();

    // useEffect(() => {
    //     const objString = localStorage.getItem('token');
    //     if (objString != null) {
    //         const obj = JSON.parse(objString);
    //         if (Date.now() > obj.expire) {
    //             localStorage.removeItem('token');
    //             dispatch(checkLogin(false));
    //             navigate('/')
    //         }
    //         else {
    //             dispatch(checkLogin(true));
    //         }
    //     }
    //     else {
    //         dispatch(checkLogin(false));
    //         navigate('/')
    //     }
    // }, [])

    const onChangeSearch = (e) => {
        setSearch(e.target.value)
    }
    const onSpinner = () => {
        setSpinner(true)
        setTimeout(() => {
            setSpinner(false)
        }, 500)
    }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            setReload(e => !e)
        }
    };

    const excelData = () => {
        // let arr = []
        // if (memberDatalist) {
        //     for (let i = 0; i < memberDatalist.length; i++) {
        //         let t = memberDatalist[i]
        //         arr.push({
        //             "RFRevID": t.uid,
        //             "Email": t.email ? t.email : "-",
        //             "Mobile": t.mobile ? t.mobile : "-",
        //             "Level": t.level,
        //             "Master": t.level == "AGENT" ? t.master ? t.master : "0" : "N/A",
        //             "Status": t.status,
        //         })
        //     }
        // }
        // Excel(arr, SelectType, ex_name)
    }

    const [data, setData] = useState([])

    useEffect(() => {
        onSpinner()
        setPage(1)
        UpgradeData()
    }, [reload])

    const UpgradeData = async () => {
        await axios.get(process.env.REACT_APP_API + '/api/budget', {
            // headers: { Authorization: `Bearer ${JWT}` }
        }).then((response) => {
            let tmp = response.data
            if (tmp?.code == 1000) {
                console.log(tmp.data)
                setData(tmp?.data?.budget ?? [])
            }
        }).catch((error) => {
            console.log(error)
        })
    };

    let slice_data = data.slice((page - 1) * 20, (page) * 20)

    return (
        <div className='main'>
            {dianModal && (<DiaModal darkname={darkname} setDiaModal={setDiaModal} clickId={clickId} setReload={setReload} />)}

            {/* <div className='budget-box'>
                <div style={{ marginBottom: "20px", fontSize: "18px", fontWeight: "bold" }}>기본설정</div>
                <div className='budget-1'>
                    <div className='budget-1-1'>
                        <div className='budget-1-3'>일일 버짓 총량</div>
                        <div className='budget-1-2'>100,000</div>
                    </div>
                    <div className='budget-1-1'>
                        <div className='budget-1-3'>버짓 배율</div>
                        <div className='budget-1-2'>2.5</div>
                    </div>
                </div>
                <div className='budget-2'>
                    <div className='budget-1-3'>사용된 버짓</div>
                    <div className='budget-1-2'>000</div>
                </div>
                <div className='budget-2'>
                    <div className='budget-1-3'>남은 버짓</div>
                    <div className='budget-1-2'>000</div>
                </div>
            </div> */}
            <div className={`main-box ${darkname}`}>
                <div className='tradingType-1' style={{ marginTop: "10px" }} >
                    <div className={`tradingType-1-2 ${darkname}`} onClick={() => excelData()}>Excel download</div>
                </div>
                <div className={`member-2 ${darkname}`}>
                    <div className={`table-head ${darkname}`}>
                        {/* <div>ID</div> */}
                        <div style={{ width: "20%", minWidth: "150px" }}>날짜</div>
                        <div style={{ width: "20%", minWidth: "150px" }}>전체 버짓</div>
                        <div style={{ width: "20%", minWidth: "150px" }}>사용 버짓</div>
                        <div style={{ width: "20%", minWidth: "150px" }}>남은 버짓</div>
                        <div style={{ width: "20%", minWidth: "150px" }}>수정</div>
                    </div>
                    <div className='table-body' style={{ minHeight: "600px" }}>
                        {
                            spinner
                                ?
                                <div className='spinner-modal'>
                                    <SyncLoader color="#003577" />
                                </div>
                                :
                                data.length === 0
                                    ?
                                    <div className='data-nofound'>
                                        <img src='/img/img_nofound.png'></img>
                                        <img src='/img/img_nofound_dark.svg' style={{ display: "none" }}></img>
                                        <p>NO RECORDS FOUND.</p>
                                    </div>
                                    :
                                    <>
                                        {
                                            slice_data.map(function (a, i) {
                                                return (
                                                    <MemberItem key={i} item={a} darkname={darkname} setClickId={setClickId} setDiaModal={setDiaModal} />
                                                )
                                            })
                                        }
                                        <NewPage initialData={data} page={page} setPage={setPage} maxFirstPage={20} />
                                    </>

                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

function MemberItem({ item, darkname, setClickId, setDiaModal }) {
    const [checkDate, setCheckDate] = useState(false)

    useEffect(() => {
        CheckDate()
    }, [])

    const CheckDate = () => {
        const today = new Date();
        if (today < new Date(item.bdg_date)) setCheckDate(true)
    }

    const onClickData = () => {
        if (checkDate) {
            setClickId(item);
            setDiaModal(true)
        }
    }

    return (
        <>
            <div className={`table-body-tr member ${darkname}`}>
                <div className='td' style={{ width: "20%", minWidth: "150px" }}>{item.bdg_date}</div>
                <div className={`${checkDate ? "onclick" : ""} right td`} style={{ width: "20%", minWidth: "150px" }} onClick={() => onClickData()}>{item.bdg_amount}</div>
                <div className='right td' style={{ width: "20%", minWidth: "150px" }}>{item.bdg_used}</div>
                <div className='right td' style={{ width: "20%", minWidth: "150px" }}>{item.bdg_remain}</div>
                <div className='td' style={{ width: "20%", minWidth: "150px" }}></div>
            </div>
        </>
    )
}

function DiaModal({ darkname, setDiaModal, clickId, setReload }) {
    const [input, setInput] = useState("")
    const onChangeInput = (e) => {
        const { value } = e.target
        const onlyNumber = value.replace(/[^0-9]/g, "")
        setInput(onlyNumber)
    }

    const onSubmitUpdateGold = async () => {
        await axios.post(process.env.REACT_APP_API + '/api/budget/update', {
            budgetId: clickId.id,
            bdgAmount: input
        }, {
        }).then((response) => {
            let tmp = response.data
            if (tmp?.code == 1000) {
                setDiaModal(false)
                setReload(e => !e)
            }
        }).catch((error) => {
            console.log(error)
        })
    };

    return (
        <div className='modal'>
            <div className='modal-box'>
                <div className='modal-1' style={{ width: "400px" }}>
                    <div className='modal-1-header'>버짓 수정</div>

                    <div style={{ marginBottom: "30px" }}>
                        <div style={{ width: "100%", textAlign: "left" }}>
                            <div style={{ fontSize: "14px", paddingBottom: "3px" }}>기존 버짓</div>
                            <div className={`password-1 ${darkname}`}>
                                <div>{clickId.bdg_amount}</div>
                            </div>
                        </div>
                        <div style={{ width: "100%", textAlign: "left" }}>
                            <div style={{ fontSize: "14px", paddingBottom: "3px" }}>수정된 버짓</div>
                            <div className={`password-1 ${darkname}`}>
                                <input value={input} onChange={onChangeInput} placeholder='수정된 버짓' />
                            </div>
                        </div>
                    </div>
                    <div className='modal-btn-box'>
                        <div className={`modal-btn ${darkname}`} onClick={() => { onSubmitUpdateGold() }}>Confirm</div>
                        <div className={`modal-btn ${darkname}`} onClick={() => setDiaModal(false)}>Close</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Budget;