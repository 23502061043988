import './css/main.css'
import { Link, useNavigate } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass, faCircleXmark, faSortDown, faSortUp, faWindowMinimize } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useRef, useState } from 'react';
import Sidebar from '../Nav/sidebar';
import NewPage from './NewPage'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { checkLogin } from '../store';
import { SyncLoader } from "react-spinners";
import Message from './common/message';

import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

function UpgradeSetting() {
    const [search, setSearch] = useState("")
    const [select, setSelect] = useState(false)
    const [selectValue, setSelectValue] = useState("Email")
    const [page, setPage] = useState(1)
    const [memberData, setMemberData] = useState([])
    const [memberDatalist, setMemberDataList] = useState([])
    const [reload, setReload] = useState(false)
    const [spinner, setSpinner] = useState(false)
    const [kol0List, setKol0List] = useState("")
    const [message, setMessage] = useState({ code: -1, msg: "" })

    let dark = useSelector((state) => { return state.Darkmode.value });
    let SelectType = useSelector((state) => { return state.SelectType.value });
    let ex_name = useSelector((state) => { return state.ex_name.value });

    const darkname = dark ? 'dark' : "";

    let dispatch = useDispatch();
    const navigate = useNavigate();

    // useEffect(() => {
    //     const objString = localStorage.getItem('token');
    //     if (objString != null) {
    //         const obj = JSON.parse(objString);
    //         if (Date.now() > obj.expire) {
    //             localStorage.removeItem('token');
    //             dispatch(checkLogin(false));
    //             navigate('/')
    //         }
    //         else {
    //             dispatch(checkLogin(true));
    //         }
    //     }
    //     else {
    //         dispatch(checkLogin(false));
    //         navigate('/')
    //     }
    // }, [])

    const onChangeSearch = (e) => {
        setSearch(e.target.value)
    }

    // useEffect(() => {
    //     if (memberData && memberData.length > 0) {
    //         if (selectValue == "Email" && search.length > 0) {
    //             filter = memberData.filter((p) => {
    //                 if (p.email) return p.email.toLocaleLowerCase().includes(search.toLocaleLowerCase())
    //             })
    //         }
    //         else if (selectValue == "Mobile" && search.length > 0) {
    //             filter = memberData.filter((p) => {
    //                 if (p.mobile) return p.mobile.toLocaleLowerCase().includes(search.toLocaleLowerCase())
    //             })
    //         }
    //         else if (selectValue == `${ex_name} ID` && search.length > 0) {
    //             filter = memberData.filter((p) => {
    //                 if (p.uid) return String(p.uid).toLocaleLowerCase().includes(search.toLocaleLowerCase())
    //             })
    //         }
    //         else if (selectValue == "EXUID" && search.length > 0) {
    //             filter = memberData.filter((p) => {
    //                 return (p.binance_exuid && p.binance_exuid.toLocaleLowerCase().includes(search.toLocaleLowerCase()) || p.okx_exuid && p.okx_exuid.toLocaleLowerCase().includes(search.toLocaleLowerCase()))
    //             })
    //         }
    //         else {
    //             filter = memberData
    //         }
    //         setMemberDataList(filter)
    //     }
    // }, [memberData])

    const onSpinner = () => {
        setSpinner(true)
        setTimeout(() => {
            setSpinner(false)
        }, 500)
    }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            setReload(e => !e)
        }
    };

    const excelData = () => {
        // let arr = []
        // if (memberDatalist) {
        //     for (let i = 0; i < memberDatalist.length; i++) {
        //         let t = memberDatalist[i]
        //         arr.push({
        //             "RFRevID": t.uid,
        //             "Email": t.email ? t.email : "-",
        //             "Mobile": t.mobile ? t.mobile : "-",
        //             "Level": t.level,
        //             "Master": t.level == "AGENT" ? t.master ? t.master : "0" : "N/A",
        //             "Status": t.status,
        //         })
        //     }
        // }
        // Excel(arr, SelectType, ex_name)
    }


    const [data, setData] = useState([])


    useEffect(() => {
        onSpinner()
        setPage(1)
        UpgradeData()
    }, [reload])

    const UpgradeData = async () => {
        await axios.get(process.env.REACT_APP_API + '/api/up', {
            // headers: { Authorization: `Bearer ${JWT}` }
        }).then((response) => {
            let tmp = response.data
            if (tmp?.code == 1000) {
                console.log(tmp.data)
                setData(tmp?.data?.upgrade['energy-limit'] ?? [])
            }
        }).catch((error) => {
            console.log(error)
        })
    };

    let slice_data = data.slice((page - 1) * 20, (page) * 20)

    return (
        <div className='main'>
            <Message message={message} />
            <Sidebar page={0} />
            <div className={`main-box ${darkname}`}>
                {/* <div className='member-1'>
                    <div className={`member-2-search ${darkname}`}>
                        <div className={`member-1-select ${darkname}`} onClick={() => setSelect(e => !e)}>
                            <div className={`member-1-select-1 ${darkname}`}>{selectValue}</div>
                            {
                                select
                                    ?
                                    <FontAwesomeIcon icon={faSortUp} />
                                    :
                                    <FontAwesomeIcon icon={faSortDown} />
                            }
                            {
                                select
                                    ? <>
                                        <div className='modal' onClick={() => setSelect(true)}></div>
                                        <div className={`member-1-select-box ${darkname}`}>
                                            <div onClick={() => { setSelectValue("Email"); setSelect(true) }}>Email</div>
                                            <div onClick={() => { setSelectValue("Mobile"); setSelect(true) }}>Mobile</div>
                                            <div onClick={() => { setSelectValue(`ID`); setSelect(true) }}>ID</div>
                                        </div>
                                    </>
                                    :
                                    ""
                            }
                        </div>
                        <FontAwesomeIcon icon={faWindowMinimize} className='line' />
                        <div className={`member-2-search-1 ${darkname}`}>
                            <input value={search} onChange={onChangeSearch} placeholder='Search' onKeyDown={handleKeyPress} />
                            <FontAwesomeIcon icon={faMagnifyingGlass} className='member-1-search-ico-3' />
                            {
                                search.length > 0
                                    ?
                                    <FontAwesomeIcon icon={faCircleXmark} className='member-1-search-ico-2' onClick={() => setSearch("")} />
                                    :
                                    ""
                            }
                        </div>
                    </div>
                    <div className={`search-btn ${darkname}`} onClick={() => setReload(e => !e)}>Search</div>
                </div> */}
                <div className='tradingType-1' style={{ marginTop: "10px" }} >
                    <div className={`tradingType-1-2 ${darkname}`} onClick={() => excelData()}>Excel download</div>
                </div>
                <div className={`member-2 ${darkname}`}>
                    <div className={`table-head ${darkname}`}>
                        <div>강화명</div>
                        <div>레벨</div>
                        <div>밸류</div>
                        <div>비용</div>
                    </div>
                    <div className='table-body' style={{ minHeight: "600px" }}>
                        {
                            spinner
                                ?
                                <div className='spinner-modal'>
                                    <SyncLoader color="#003577" />
                                </div>
                                :
                                data.length === 0
                                    ?
                                    <div className='data-nofound'>
                                        <img src='/img/img_nofound.png'></img>
                                        <img src='/img/img_nofound_dark.svg' style={{ display: "none" }}></img>
                                        <p>NO RECORDS FOUND.</p>
                                    </div>
                                    :
                                    <>
                                        {
                                            slice_data.map(function (a, i) {
                                                return (
                                                    <MemberItem key={i} item={a} darkname={darkname} dark={dark} setReload={setReload} kol0List={kol0List} setMessage={setMessage} />
                                                )
                                            })
                                        }
                                        <NewPage initialData={data} page={page} setPage={setPage} maxFirstPage={20} />
                                    </>

                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

function MemberItem({ item, darkname, dark, setReload, kol0List, setMessage }) {
    let SelectType = useSelector((state) => { return state.SelectType.value });
    const [clickId, setClickId] = useState(false)
    const [clickEXUID, setClickEXUID] = useState(false)
    const [EXUIDtype, setEXUIDtype] = useState(0)

    return (
        <>
            <div className={`table-body-tr ${darkname}`}>
                <div className='td'>{item.up_type}</div>
                <div className='td'>{item.up_level}</div>
                <div className='td'>{item.up_value}</div>
                <div className='td'>{item.up_price}</div>
            </div>
        </>
    )
}

export default UpgradeSetting;