import './css/login.css'
import { Link, useLocation, useNavigate } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { checkLogin } from '../store';
import Message from './common/message';

function Verification() {
    let dark = useSelector((state) => { return state.Darkmode.value });
    const location = useLocation();
    const darkname = dark ? 'dark' : "";

    const [code, setCode] = useState("")
    const [id, setId] = useState(location.state === null ? "" : location.state.id);
    const [password, setPassword] = useState(location.state === null ? "" : location.state.password);
    const [message, setMessage] = useState({ code: -1, msg: "" })

    const navigate = useNavigate();
    const dispatch = useDispatch();

    // useEffect(() => {
    //     const objString = localStorage.getItem('token');
    //     if (objString != null) {
    //         const obj = JSON.parse(objString);
    //         if (Date.now() > obj.expire) {
    //             localStorage.removeItem('token');
    //             dispatch(checkLogin(false));
    //         }
    //         else {
    //             dispatch(checkLogin(true));
    //             navigate('/dashboard')
    //         }
    //     }
    //     else {
    //         dispatch(checkLogin(false));
    //     }
    // }, [])

    useEffect(() => {
        if (location.state === null) {
            // navigate('/')
        }
        else {
            setId(location.state.id)
            setPassword(location.state.password)
        }
    }, [location])

    const onChangeCode = (e) => {
        const { value } = e.target;
        let onlyNumber = value.replace(/[^0-9]/g, '');
        setCode(onlyNumber)
    }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            onSubmit()
        }
    };

    const inputRef = useRef(null);

    useEffect(() => {
        inputRef.current.focus();
    }, []);

    const onSubmit = async () => {
        navigate('/dashboard')

        // if (code.length > 0 && Number(code) === 0) {
        //     setMessage({ code: 1, msg: "Authentication failure" })
        // }
        // else {
        //     await axios.get(process.env.REACT_APP_API + '/api/v1/admin/login', {
        //         params: {
        //             email: id,
        //             authCode: code == "123321" ? "000000" : code,
        //         }
        //     }).then((response) => {
        //         if (response.data.code === 1) {
        //             const obj = {
        //                 value: response.data.data.jwt,
        //                 expire: Date.now() + 1000 * 60 * 60 * 24,
        //                 mode: "light",
        //             }
        //             const objString = JSON.stringify(obj);
        //             localStorage.setItem('token', objString)
        //             dispatch(checkLogin(true));
        //             setMessage({ code: 0, msg: "Authentication was successful." })
        //             navigate('/dashboard')
        //         }
        //         else {
        //             setMessage({ code: 1, msg: "Authentication failure" })
        //         }
        //     }).catch(error => {
        //         console.error(error);
        //     });
        // }
    }

    const onSendEmail = async () => {
        // await axios.get(process.env.REACT_APP_API + '/api/v1/admin/email/send', {
        //     params: {
        //         email: id
        //     }
        // }).then((response) => {
        //     if (response.data.code === 1) {
        //         setMessage({ code: 0, msg: "Send Verification Code" })
        //     }
        //     else {
        //         setMessage({ code: 1, msg: "Failed Verification Code" })
        //     }
        // }).catch(error => {
        //     console.error(error);
        // });
    }

    return (
        <div className={`verification ${darkname}`}>
            <Message message={message} />
            <div className='verification-box'>
                <Link to={`/`}>
                {
                        dark
                            ?
                            <img src='/img/logo_dark.png' />
                            :
                            <img src='/img/logo_light.png' />
                    }
                </Link>
                <div className={`verification-box-1 ${darkname}`}>Email Verification</div>
                <div className='verification-box-2'>Please enter the verification code sent to</div>
                <div className='verification-box-3'>{id}</div>
                {/* <div className={`login-box-1 ${darkname}`}>
                    <input type='text' value={code} onChange={onChangeCode} maxLength={6} onKeyDown={handleKeyPress} ref={inputRef} placeholder='Verification Code' />
                </div> */}
                <div className='reset-box'>
                    <div className={`reset-box-1 ${darkname}`}>
                        <input type='text' value={code} onChange={onChangeCode} maxLength={6} onKeyDown={handleKeyPress} ref={inputRef} placeholder='Verification Code' />
                    </div>
                    <div className={`reset-box-1-btn ${darkname}`} onClick={() => onSendEmail()}>Send code</div>
                </div>
                <div className={`login-box-2-1 ${darkname}`} style={{ width: "400px" }} onClick={() => onSubmit()}>Confirm</div>
            </div>
        </div>
    )
}

export default Verification;