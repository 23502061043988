import './nav.css'
import { Link, useNavigate } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock, faMoon, faSun } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { checkLogin, checkMode, checkSelectType } from '../store';
import base64 from 'base-64';
import axios from 'axios';

function Header() {
    let login = useSelector((state) => { return state.login.value });
    let dark = useSelector((state) => { return state.Darkmode.value });
    let SelectType = useSelector((state) => { return state.SelectType.value });
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [cnt, setCnt] = useState(0);

    const darkname = dark ? 'dark' : "";

    // useEffect(() => {
    //     const objString = localStorage.getItem('token');
    //     if (objString != null) {
    //         const obj = JSON.parse(objString);
    //         let token = obj.value;
    //         let payload = token.substring(token.indexOf('.') + 1, token.lastIndexOf('.'));
    //         let dec = base64.decode(payload);
    //         // console.log(JSON.parse(dec))
    //         dispatch(checkSelectType(JSON.parse(dec).authority))

    //         if (obj.mode === 'light') {
    //             dispatch(checkMode(false));
    //         }
    //         else {
    //             dispatch(checkMode(true));
    //         }
    //     }
    // }, [login]);

    const onLogout = async () => {
        const objString = localStorage.getItem('token');
        if (objString != null) {
            const obj = JSON.parse(objString);
            let token = obj.value;
            let payload = token.substring(token.indexOf('.') + 1, token.lastIndexOf('.'));
            let dec = base64.decode(payload);

            await axios.post('https://admin.api.coinrf.io/api/admin/logout', {
                email: JSON.parse(dec).sub
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then((response) => {
            }).catch(error => {
                console.error(error);
            });
        }
    }

    const Logout = () => {
        if (window.confirm("Are you sure you want to log out?") === true) {
            // onLogout()
            localStorage.removeItem('token');
            dispatch(checkLogin(false))
            navigate('/')
        }
    }

    return (
        <div className={`header ${darkname}`}>
            <div className='header-box'>
                <div className='header-left'>
                    <Link to={`/dashboard`}>
                        {
                            dark
                                ?
                                <img src='/img/logo_dark.png' />
                                :
                                <img src='/img/logo_light.png' />
                        }
                    </Link>
                </div>
                <div className='header-right'>
                    <div className='header-right-1'>
                        {/* {
                            dark
                                ?
                                <FontAwesomeIcon icon={faMoon} className="toggle-menu-moon-icon"
                                    onClick={() => { dispatch(checkMode(false)) }} />
                                :
                                <FontAwesomeIcon icon={faSun} className="toggle-menu-sun-icon"
                                    onClick={() => { dispatch(checkMode(true)) }} />
                        } */}
                        <div className={`header-right-logout ${darkname}`} onClick={() => Logout(false)}>Log Out</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Header;