import './App.css';
import { Routes, Route, Navigate } from 'react-router-dom'
import Header from './Nav/header'
import Footer from './Nav/footer'
import Sidebar from './Nav/sidebar';
import Login from './Pages/login'
import Main from './Pages/main';
import Verification from './Pages/verification';
import Dashboard from './Pages/dashboard';
import Member from './Pages/member';
import Budget from './Pages/budget';
import Advertisement from './Pages/advertisement';
import GameSetting from './Pages/gameSetting';
import AdminSetting from './Pages/adminSetting';
import Character from './Pages/character';
import UpgradeSetting from './Pages/upgradeSetting';
import LogAccess from './Pages/logAccess';
import LogGold from './Pages/logGold';
import LogEnergy from './Pages/logEnergy';
import LogJackpot from './Pages/logJackpot';

function App() {
  return (
    <div className="App">
      <Header />
      <Sidebar />
        <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/verification" element={<Verification />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/member" element={<Member />} />
        <Route path="/budget" element={<Budget />} />
        <Route path="/advertisement" element={<Advertisement />} />
        <Route path="/gameSetting" element={<GameSetting />} />
        <Route path="/adminSetting" element={<AdminSetting />} />
        <Route path="/character" element={<Character />} />
        <Route path="/upgradeSetting" element={<UpgradeSetting />} />
        <Route path="/logAccess" element={<LogAccess />} />
        <Route path="/logGold" element={<LogGold />} />
        <Route path="/logEnergy" element={<LogEnergy />} />
        <Route path="/logJackpot" element={<LogJackpot />} />

        <Route path="*" element={<Navigate to="/" />} />
        </Routes>
        <Footer />
    </div>
  );
}

export default App;
